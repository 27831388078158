<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <VProduct v-bind:set_id="$route.params.id" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import VProduct from "@/components/sets/VOneSet.vue";

//this.$route.params.id
export default {
  name: "image",
  components: {
    VProduct
  },
  props: {
    image_id: String
  },
  data() {
    return {};
  },
  methods: {},
  mounted: function() {}
};
</script>

<style></style>
