<template>
  <div>
    <b-card class="mt-3">
      <b-card-title>Search Product Sets</b-card-title>
      <b-card-sub-title
        >Find product sets and filter them by name, collection ...</b-card-sub-title
      >

      <b-card-text class="mt-2">
        <b-form-group label-cols-lg="2" label="Set name">
          <b-input-group>
            <b-form-input
              placeholder="Enter prefix of similarity product set name"
              v-model="product_name"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Collection"
          label-for="table-style-variant"
          label-cols-lg="2"
        >
          <b-form-select
            v-model="collection_type"
            value-field="id"
            text-field="name"
            :options="product_collections"
            id="table-style-variant"
          >
            <template #first>
              <option value="">-- None --</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-row>
          <b-col cols="auto" class="mr-auto">
            <b-button-group>
              <b-button v-on:click="createGroup" variant="outline-success"
                >Create New Set</b-button
              >
              <b-button
                class="ml-1"
                v-on:click="loadGroups()"
                variant="outline-primary"
                v-b-tooltip.hover
                title="Refresh sets"
              >
                Search
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <b-table
          :items="groups"
          :fields="fields"
          class="mt-3"
          :busy="loading"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(name)="data">
            {{ data.item.name }}
            <VClipCopy
              :from="'vproduct'"
              v-bind:uuid="data.item.id"
              v-bind:name="'Product'"
              v-bind:key="'product-' + data.item.id"
            />
          </template>

          <template v-slot:cell(id)="data">
            {{ data.item.customer_product_id }}
          </template>

          <template v-slot:cell(completed)="data">
            <i v-if="data.item.completed" class="fal fa-check text-success"></i>
            <i v-else class="fal fa-times text-danger"></i>
          </template>

          <template v-slot:cell(collection)="data">
            {{ getCollectionName(data.item.product_collection).name }}
          </template>

          <template v-slot:cell(type)="data">
            {{ getCollectionName(data.item.product_collection).type }}
          </template>

          <template v-slot:cell(action)="data">
            <router-link v-bind:to="/set/ + data.item.id" target="_blank">
              <b-button
                class="mr-1"
                variant="outline-primary"
                v-b-tooltip.hover
                title="Detail"
              >
                <i class="fal fa-search"></i>
              </b-button>
            </router-link>
          </template>
        </b-table>

        <b-pagination
          v-if="!loading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          label-page
          first-number
          last-number
          size="sm"
          hide-goto-end-buttons
        />
        <b-form-input
          :state="validPage"
          class="ml-2 md"
          style="width: 100px;"
          v-model="currentPage"
          :type="'number'"
          >{{ currentPage }}</b-form-input
        >
        <h6 class="mt-4">Number of items: {{ totalRows }}</h6>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-undef */

import axios from "axios";
import { mapState } from "vuex";
import VLoading from "@/components/VLoading.vue";
import { DataLoadedEnum } from "../../constants";
import VZoomImage from "@/components/utils/VZoomImage.vue";
import VClipCopy from "../utils/VClipCopy.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VGroups",
  components: {
    VZoomImage,
    VClipCopy
  },
  props: {
    image_id: String,
    detection: String,
    group_id: String,
    job: Object,
    task: Object
  },
  computed: {
    ...mapState(["product_collections", "workspace", "data_loaded"])
  },
  data() {
    return {
      test: false,
      train: false,
      empty: false,
      present: false,
      count: null,
      product_name: "",
      collection_type: null,
      fields: ["name", "id", "collection", "type", "completed", "action"],
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      loading: true,
      groups: [],
      selected_item: null,
      select_all: false,
      select_query: false,
      mainProps: {
        height: 40,
        width: 40
      }
    };
  },
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    createGroup() {
      this.$bvModal.show("product-create");
    },
    async loadGroups({ page = 1, type = null, clear = false } = {}) {
      this.loading = true;
      console.log("Collection type", this.collection_type);
      console.log("Product name", this.product_name);

      let stype = "";
      if (this.collection_type) {
        stype = "&product_collection=" + this.collection_type;
      }

      let product_name = "";
      if (this.product_name) {
        product_name = "&search=" + this.product_name;
      }

      let response = await axios.get(
        API_URL +
          "/product/v2/set/?page_size=" +
          this.perPage +
          "&page=" +
          page +
          product_name +
          stype +
          "&workspace=" +
          this.workspace
      );

      let groups = response.data["results"];
      this.totalRows = response.data["count"];

      this.groups = groups;
      this.loading = false;
      this.refresh();
    },
    refresh: function(e) {
      if (this.job) {
        this.$emit("refresh");
      }
    },
    getCollectionName(type) {
      let item = this.product_collections.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item;
      } else {
        return { name: "Unknown", type: "Unknown" };
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadGroups();
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.images = [];
        this.loadGroups();
      }
    },
    currentPage() {
      this.loadGroups({ page: this.currentPage });
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
