import Vue from "vue";
import store from "./store.js";
import Router from "vue-router";
import Dashboard from "./views/Dashboard.vue";
import Manage from "./views/Manage.vue";
import Login from "./views/Login.vue";
import Image from "./views/Image.vue";
import Images from "./views/Images.vue";
import ImageJob from "./views/job/ImageJob.vue";
import SimilarityJob from "./views/job/SimilarityJob.vue";
import Jobs from "./views/job/Jobs.vue";
import Detection from "./views/Detection.vue";
import Segmentation from "./views/Segmentation.vue";
import Evaluations from "./views/Evaluations.vue";
import Evaluation from "./views/Evaluation.vue";
import Groups from "./views/Groups.vue";
import Products from "./views/Products.vue";
import Group from "./views/Group.vue";
import Product from "./views/Product.vue";
import Label from "./views/Label.vue";
import Sets from "./views/Sets.vue";
import OneSet from "./views/OneSet.vue";

Vue.use(Router);

function loadView(view) {
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

let router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      path: "/",
      component: Dashboard,
      name: "Dashboard",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/images",
      component: Images,
      name: "Images",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/groups",
      component: Groups,
      name: "Groups",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/products",
      component: Products,
      name: "Products",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sets",
      component: Sets,
      name: "Sets",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/set/:id",
      component: OneSet,
      name: "Set",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/jobs",
      component: Jobs,
      name: "Jobs",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/detection",
      component: Detection,
      name: "Detection",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/segmentation",
      component: Segmentation,
      name: "Segmentation",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/evaluation",
      component: Evaluations,
      name: "Evaluations",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/evaluation/:id",
      name: "Evaluation",
      component: Evaluation,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/manage",
      component: Manage,
      name: "Manage",
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/group/:id",
      name: "Group",
      component: Group,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/product/:id",
      name: "Product",
      component: Product,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/label/:id",
      name: "Label",
      component: Label,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/image/:id",
      name: "Image",
      component: Image,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/imagejob/:id",
      name: "ImageJob",
      component: ImageJob,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/similarityjob/:id",
      name: "SimilarityJob",
      component: SimilarityJob,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
